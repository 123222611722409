var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading && _vm.data.properties.filed_content != 'Hide')?_c('div',{style:(_vm.getElementStyle)},[(!_vm.isDefalutPos)?_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"white-space":"nowrap"}},[(_vm.hasLabel && !_vm.data.properties.hideLabel)?_c('label',{style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.label||_vm.data.label))]):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),(_vm.data.properties.is_unique)?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-left":"1%"}},[_c('i',{staticClass:"el-icon-attract"})]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]),_c('el-col',{staticStyle:{"display":"contents"},attrs:{"span":_vm.showLabel
      &&
      !_vm.isDefalutPos
      ?
      12
      :
      24}},[(_vm.isList && !_vm.isView && _vm.data.properties.filed_content != 'Hide')?_c('el-date-picker',{style:(_vm.getStyle),attrs:{"type":"datetimerange","prefix-icon":"el-icon-date","disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"placeholder":_vm.data.placeholder || _vm.data.label,"range-separator":"To","start-placeholder":"Start date","end-placeholder":"End date","popper-class":"my-popover","format":'MM-dd-yyyy HH:mm:ss',"readonly":_vm.readonly()},on:{"change":_vm.onchange},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(!_vm.isView && !_vm.isList && _vm.data.properties.filed_content != 'Hide')?_c('el-date-picker',{style:(_vm.getStyle),attrs:{"prefix-icon":"el-icon-date","size":_vm.profilePage ? 'mini' : '',"type":"datetimerange","placeholder":_vm.data.placeholder || _vm.data.label,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"range-separator":"To","start-placeholder":_vm.data.placeholder || 'Start date',"end-placeholder":_vm.data.placeholder1|| 'End date',"popper-class":"my-popover","format":'MM-dd-yyyy HH:mm:ss',"readonly":_vm.readonly()},on:{"change":_vm.onchange},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(
          _vm.isView &&
          _vm.form[this.data.key] &&
          _vm.data.properties.filed_content != 'Hide'
        )?_c('p',{style:(_vm.getStyle)},[_vm._l((_vm.form[this.data.key].slice(0, 1)),function(item){return [_vm._v(" "+_vm._s(_vm._f("moment")(item,"MM-DD-YYYY HH:mm:ss"))+" (From ~ To) ")]}),_vm._l((_vm.form[this.data.key].slice(1, 2)),function(item){return [_vm._v(" "+_vm._s(_vm._f("moment")(item,"MM-DD-YYYY HH:mm:ss"))+" ")]})],2):_c('p')],1)],1):_c('el-row',{attrs:{"align":"middle"}},[(!_vm.data.properties.hideLabel)?_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPos ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel && !_vm.data.properties.hideLabel)?_c('label',{style:(_vm.getLabelStyles),attrs:{"for":"text"}},[_vm._v(_vm._s(_vm.label||_vm.data.label))]):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),(_vm.data.properties.is_unique)?_c('span',{staticStyle:{"color":"red","font-weight":"bold","margin-left":"1%"}},[_c('i',{staticClass:"el-icon-attract"})]):_vm._e(),_c('span',[(_vm.data.description)?_c('el-popover',{attrs:{"placement":"top-start","trigger":"hover","content":_vm.data.description}},[_c('i',{staticClass:"el-icon-info",attrs:{"slot":"reference"},slot:"reference"})]):_vm._e()],1)]):_c('el-col',{ref:"colRef",style:(_vm.computedStyles),attrs:{"span":_vm.showLabel  ? 4 : _vm.data.description ? 20 : 24}},[(_vm.hasLabel)?_c('span',{staticStyle:{"color":"white","display":"inline-block","height":"20px","opacity":"0"}}):_vm._e(),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e()]),_c('el-col',{attrs:{"span":_vm.showLabel
      &&
      !_vm.isDefalutPos
      ?
      12
      :
      24}},[(_vm.isList && !_vm.isView && _vm.data.properties.filed_content != 'Hide')?_c('el-date-picker',{style:(_vm.getStyle),attrs:{"type":"datetimerange","prefix-icon":"el-icon-date","disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"placeholder":_vm.data.placeholder || _vm.data.label,"range-separator":"To","start-placeholder":"Start date","end-placeholder":"End date","popper-class":"my-popover","format":'MM-dd-yyyy HH:mm:ss',"readonly":_vm.readonly()},on:{"change":_vm.onchange},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(!_vm.isView && !_vm.isList && _vm.data.properties.filed_content != 'Hide')?_c('el-date-picker',{class:{ 'highlighted-border': _vm.highlight },style:(_vm.getStyle),attrs:{"prefix-icon":"el-icon-date","size":_vm.profilePage ? 'mini' : '',"type":"datetimerange","placeholder":_vm.data.placeholder || _vm.data.label,"disabled":_vm.isFromDocument ? false : _vm.checkReadonly(),"range-separator":"To","start-placeholder":_vm.data.placeholder || 'Start date',"end-placeholder":_vm.data.placeholder1|| 'End date',"popper-class":"my-popover","format":'MM-dd-yyyy HH:mm:ss',"readonly":_vm.readonly()},on:{"change":_vm.onchange},model:{value:(_vm.form[_vm.data.key]),callback:function ($$v) {_vm.$set(_vm.form, _vm.data.key, $$v)},expression:"form[data.key]"}}):_vm._e(),(
          _vm.isView &&
          _vm.form[this.data.key] &&
          _vm.data.properties.filed_content != 'Hide'
        )?_c('p',{style:(_vm.getStyle)},[_vm._l((_vm.form[this.data.key].slice(0, 1)),function(item){return [_vm._v(" "+_vm._s(_vm._f("moment")(item,"MM-DD-YYYY HH:mm:ss"))+" (From ~ To) ")]}),_vm._l((_vm.form[this.data.key].slice(1, 2)),function(item){return [_vm._v(" "+_vm._s(_vm._f("moment")(item,"MM-DD-YYYY HH:mm:ss"))+" ")]})],2):_c('p')],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }